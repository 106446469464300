import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  z-index: 4;
  overflow: hidden;
  top: 0;
  right: -275px;
  width: 0;
  opacity: 0;
  height: 100%;
  background-color: #fff;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);

  .a-logo {
    text-align: center;
    padding-top: 2em;
  }
  
  .a-leaves {
    position: relative;
    @media (max-width: 1220px) {
      bottom: 20em;
      z-index: -1;
    }
    @media (max-width: 768px) {
      bottom: 13em;
    }
  }
  
  ${({ active }) =>
    active &&
    `
			width: 20%;
			right: 0px;
			opacity: 1;

			@media (max-width: 1220px) {
				width: 100%;
			}

			@media (max-width: 768px) {
				width: 100%;
			}
	`}
`;
