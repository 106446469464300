import styled from 'styled-components';

export const Wrapper = styled.div`
  a {
    color: #000;
    text-decoration: none;
		margin: 0 3em;
		text-align: left;
		cursor: pointer;
	}
	@media (max-width: 1220px) {
		color: #000;
	}
  }

	.a-link {
			@media (max-width: 1220px) {
				color: #000;
				display: block;
				text-align: center;
			}
	}

  ${({ desktop }) => 
    desktop
      ? `
			align-items: center;
			display: flex;

			@media (max-width: 1220px) {
					display: none;
			}

/*			a {
					margin-right: 1rem;

					&:last-child {
							margin-right: unset;
					}
			}*/
		`
      : `
			padding: 3rem;
			display: flex;
			flex-direction: column;

/*			a {
					margin-bottom: 1rem;

					&:last-child {
							margin-bottom: unset;
					}
			}*/
	`}
`;