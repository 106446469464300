import "./m-navbar.scss";

// import logo from "assets/icons/logo.svg";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

import NavbarLinks from "../navbarLinks";

const Navbar: React.FC = () => {
  return (
    <div className={"m-navbar"}>
      <Link to="/">
        <StaticImage
          src={"../../../../assets/icons/logo.svg"}
          alt={"logo"}
          width={150}
          height={121}
        />
      </Link>
      <NavbarLinks desktop />
    </div>
  );
};

export default Navbar;
