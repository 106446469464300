module.exports = {
    defaultTitle: 'Terapia personal Flores de Bach. Gestión emocional AlmaySer',
    author: 'Maria Casanovas Oliete',
    siteUrl: 'https://almayser.es/',
    url: 'https://almayser.es/',
    legalName: 'Almayser',
    defaultDescription: 'Haz florecer lo mejor que hay en ti con AlmaySer. Terapias florales personalizadas para tu bienestar emocional. Entra y comienza tu cambio.',
    logo: 'https://almayser.es/thumbnail.png',
    socialLinks: {
        facebook: 'https://www.facebook.com/almayser.es/',
        twitter: '',
        linkedin: '',
        instagram: 'https://www.instagram.com/almayser.es/',
        telegram: '',
        vimeo: '',
        youtube: '',
        tiktok: 'https://www.tiktok.com/@almayser',
        medium: '',
        spotify: '',
        podcast: '',
    },
    social: {
        facebook: '@almayser.es',
        twitter: '@',
        linkedin:'@',
        instagram:'@almayser.es',
        tiktok: '@almayser',
        telegram: '@almayser',
    },
    address: {
        streetAddress: 'Carrer Gran de Sant Andreu, 505',
        city: 'Barcelona',
        region: 'Barcelona',
        country: 'España',
        zipCode: '08030',
    },
    contact: {
        email: 'info@almayser.es',
        phone: '634464316',
},
foundingDate: '2021',
};