import logo from "assets/icons/logo.svg";
import React from "react";

import leaves from "../../../../assets/vectors/leaf-right.svg";
import NavbarLinks from "../navbarLinks";
import { Wrapper } from "./styles";

const Sidebar = ({ sidebar, toggle }) => {
  return (
    <Wrapper active={sidebar} onClick={toggle}>
      <div className={"a-logo"}>
        <img
          src={logo}
          alt="Almayser | Terapias florales"
          width="150"
          height="121"
        />
      </div>
      <NavbarLinks />
      <img src={leaves} alt="Almayser | Terapias florales" className={"a-leaves"} width="100%"/>
    </Wrapper>
  );
};

export default Sidebar;
