import "./modal-form.scss";

// import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { PropsWithChildren } from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import close from "../../assets/icons/close.svg";
import withForm from "./ContactForm/withForm";

interface Props extends PropsWithChildren<any> {
  title: string;
  is_active: any;
  form: any;
}

export const ModalForm: React.FC<Props> = (props: Props) => {
  const CitaWithFrom = withForm(props.form);
  return (
    <div className="o-modal-form" id="contact">
      <div className={"m-modal-form__container"}>
        <div className={"o-modal-form__flex"}>
          <p className={"h2 a-modal-form__title"}>{props.title}</p>
          <img
            src={close}
            className={"a-close"}
            onClick={() => {
              props.is_active(false);
            }}
          />
        </div>
        <CitaWithFrom />
        <div className="o-modal-form__contact">
          <p>
            Puedes enviarme un{" "}
            <a
              href={"https://wa.me/+34634464316"}
              target={"_blank"}
              rel={"noopener noreferrer nofollow"}
            >
              {" "}
              whastapp
            </a>{" "}
            ó un mensaje por telegram si lo prefieres al <b>634464316</b>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ModalForm;
