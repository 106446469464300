import { useState } from "react";

import { Subscriber } from "../contexts/subscriptions/domain/subscriber";
import { SubscriptionMailRelay } from "../contexts/subscriptions/infrastructure/mailrelay";

const useSubscription = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const data = new FormData(e.target);
    const email: string = data.get("email").toString();
    let is_news: boolean = false;
    let is_professional: boolean = false;
    if (data.get("novedades") && data.get("novedades") === "sí") {
      is_news = true;
    }
    if (data.get("profesional") && data.get("profesional") === "sí") {
      is_professional = true;
    }
    const subscriber: Subscriber = {
      email,
      is_professional,
      is_news,
    };
    const subscription = new SubscriptionMailRelay();
    subscription
      .addEmail(subscriber)
      .then((e) => {
        setSubmitting(false);
        setSubmitted(false);
        setSuccess(true);
        setSubmitted(true);
      })
      .catch((error) => {
        setSubmitting(false);
        setSubmitted(true);
        setSuccess(false);
        setFail(true);
      });
  };
  return {
    handleSubmit,
    submitting,
    submitted,
    success,
    fail,
  };
};

export default useSubscription;
