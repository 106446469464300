exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-aviso-legal-mdx": () => import("./../../../src/pages/legal/aviso-legal.mdx" /* webpackChunkName: "component---src-pages-legal-aviso-legal-mdx" */),
  "component---src-pages-legal-politica-de-compras-mdx": () => import("./../../../src/pages/legal/politica-de-compras.mdx" /* webpackChunkName: "component---src-pages-legal-politica-de-compras-mdx" */),
  "component---src-pages-legal-politica-de-cookies-mdx": () => import("./../../../src/pages/legal/politica-de-cookies.mdx" /* webpackChunkName: "component---src-pages-legal-politica-de-cookies-mdx" */),
  "component---src-pages-legal-politica-de-privacidad-mdx": () => import("./../../../src/pages/legal/politica-de-privacidad.mdx" /* webpackChunkName: "component---src-pages-legal-politica-de-privacidad-mdx" */),
  "component---src-pages-listado-flores-bach-js": () => import("./../../../src/pages/listado-flores-bach.js" /* webpackChunkName: "component---src-pages-listado-flores-bach-js" */),
  "component---src-pages-preparado-floral-js": () => import("./../../../src/pages/preparado-floral.js" /* webpackChunkName: "component---src-pages-preparado-floral-js" */),
  "component---src-pages-remedio-js": () => import("./../../../src/pages/remedio.js" /* webpackChunkName: "component---src-pages-remedio-js" */),
  "component---src-pages-sobre-mi-js": () => import("./../../../src/pages/sobre-mi.js" /* webpackChunkName: "component---src-pages-sobre-mi-js" */),
  "component---src-pages-tienda-js": () => import("./../../../src/pages/tienda.js" /* webpackChunkName: "component---src-pages-tienda-js" */),
  "component---src-templates-blog-comunidad-category-tsx": () => import("./../../../src/templates/blog/comunidadCategory.tsx" /* webpackChunkName: "component---src-templates-blog-comunidad-category-tsx" */),
  "component---src-templates-blog-comunidad-tsx": () => import("./../../../src/templates/blog/comunidad.tsx" /* webpackChunkName: "component---src-templates-blog-comunidad-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog/post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-consulta-escrita-consulta-escrita-js": () => import("./../../../src/templates/consulta-escrita/consulta-escrita.js" /* webpackChunkName: "component---src-templates-consulta-escrita-consulta-escrita-js" */)
}

