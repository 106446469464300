import "styles/index.scss";

import React from "react";

import {
  availableDays,
  availableHours,
  availableTimesZones,
} from "../../ModalForm/ContactForm/CitaOnline";
import { Footer } from "../footer";
import BannerCookies from "../GTM/Banner";
import Header from "../header";

const Layout = ({ children }) => {
  // const theme = { primaryColor: '#00E787' };
  // const theme = require('sass-extract-loader?{"plugins": ["sass-extract-js"]}!styles/settings/variables_jsx.scss');
  // console.log( 'theme ' + JSON.stringify(theme));

  return (
    <>
      <form
        name="contacto"
        method="post"
        data-netlify="true"
        netlify-honeypot="bot-field"
        hidden
      >
        <input type="text" name="nombre" />
        <input type="email" name="email" />
        <textarea name="mensaje" />
        <input type="checkbox" name="politica" />
      </form>
      <form
        name="pide-cita"
        method="post"
        data-netlify="true"
        netlify-honeypot="bot-field"
        hidden
      >
        <input type="text" name="nombre" />
        <input type="email" name="email" />
        {availableDays.map((day, index) => (
          <input key={index} type={"checkbox"} name={day} />
        ))}
        <select name="timeZone">
          <option value="A cualquier hora">A cualquier hora</option>
          <option value="A primera hora">A primera hora</option>
          <option value="Al mediodía">Al mediodía</option>
          <option value="Por la tarde">Por la tarde</option>
          <option value="A última hora">A última hora</option>
          <option value="Más opciones">Más opciones</option>
        </select>
        {availableHours.map((hour, index) => (
          <input key={index} type={"checkbox"} name={`hour${hour}`} />
        ))}
        <textarea name="observaciones" />
        <input type="checkbox" name="politica" />
      </form>
      <BannerCookies />
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
