import { Subscriber } from "../domain/subscriber";
import { Subscription } from "../domain/subscription";

export const api = "/api/v1";

export class SubscriptionMailRelay implements Subscription {
  addEmail(subscriber: Subscriber): Promise<any> {
    const groups_ids: number[] = [2];
    if (subscriber.is_news) {
      groups_ids.push(1);
    }
    if (subscriber.is_professional) {
      groups_ids.push(3);
    }
    return fetch(`/.netlify/functions/subscribe-in-mailrelay`, {
      method: "POST",
      body: JSON.stringify({
        status: "inactive",
        restore_if_deleted: true,
        email: subscriber.email,
        group_ids: groups_ids,
      }),
    }).then((response) => {
      return response.json();
    });
  }
}
