import { Link } from "gatsby";
import * as React from "react";
import { PropsWithChildren, useState } from "react";

import ModalForm from "../../../ModalForm";
import { CitaOnline } from "../../../ModalForm/ContactForm/CitaOnline";
import { Wrapper } from "./styles";

interface Props extends PropsWithChildren<any> {
  desktop?: boolean;
}

const NavbarLinks: React.FC<Props> = (props: Props) => {
  const [has_modal, setHasModal] = useState(false);
  // const [has_desktop, setHasDesktop] = useState(props.desktop);

  function buttonClick(e) {
    // e.stopPropagation();
    // e.preventDefault();
    // setHasDesktop(false);
    setHasModal(true);
  }

  return (
    <>
      {has_modal && (
        <ModalForm
          title="Consulta online"
          form={CitaOnline}
          is_active={setHasModal}
        />
      )}
      <Wrapper desktop={props.desktop}>
        <Link to="/" className={"a-link"}>
          Inicio
        </Link>
        <Link to="/sobre-mi/" className={"a-link"}>
          Sobre mí
        </Link>
        <Link to="/comunidad/" className={"a-link"}>
          Comunidad
        </Link>
        <Link to="/tienda/" className={"a-link"}>
          Tienda
        </Link>
        {props.desktop && (
          <div className={"circle--green-cta circle--responsive"}>
            <a
              className={
                "a-link a-cta-menu a-link--underline a-link--no-underline-media"
              }
              onClick={(e) => {
                buttonClick(e);
              }}
            >
              Consulta online
            </a>
          </div>
        )}
      </Wrapper>
    </>
  );
};

export default NavbarLinks;
