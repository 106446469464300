import * as React from "react";

import useSubscription from "../../../hooks/useSubscription";

function withSubscriber(Form) {
  const { handleSubmit, submitting, success, fail } = useSubscription();
  return class x extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return <Form {...{ handleSubmit, submitting, success, fail }} />;
    }
  };
}

export default withSubscriber;
