import "./contact-form.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

interface PropsForm extends PropsWithChildren<any> {
  handleSubmit: any;
  submitting: boolean;
  success: boolean;
  fail: boolean;
}

export const Unete = (props: PropsForm) => (
  <form
    name="comunidad"
    method="post"
    onSubmit={props.handleSubmit}
    className="m-form"
  >
    <label hidden>Email </label>
    <input type="email" name="email" placeholder="Email" required />
    <div className={"m-form__checks"}>
      <div className={"a-private"}>
        <label htmlFor="novedades">Quiero recibir todas las novedades</label>
        <input type="checkbox" name="novedades" id="novedades" value="sí" />
      </div>
      <div className={"a-private"}>
        <label htmlFor="profesional">Soy profesional</label>
        <input type="checkbox" name="profesional" id="profesional" value="sí" />
      </div>
    </div>
    <div className={"a-private"}>
      <label htmlFor="politica">
        Acepto la{" "}
        <a
          href="/legal/politica-de-privacidad/"
          target="_blank"
          rel="nofollow noopener noreferrer"
          className={"a-policy"}
        >
          política de privacidad
        </a>
      </label>
      <input
        type="checkbox"
        name="politica"
        id="politica"
        value="acepta-politica"
        required
      />
    </div>

    <div className="center">
      {!props.success && (
        <button type="submit" className={"a-button"}>
          Unirme {props.submitting && <span className="a-spinner" />}
        </button>
      )}
    </div>
    {props.fail && (
      <span className="a-fail">
        Ha ocurrido un <b>error</b>, puedes solicitar la infografia mandando un
        correo a{" "}
        <a
          href="mailto:info@almayser.es"
          target={"_blank"}
          rel={"noopener noreferrer nofollow"}
        >
          info@almayser.es
        </a>{" "}
      </span>
    )}
    {props.success && (
      <span className="a-success">
        ¡Gracias! Enseguida{" "}
        <b>recibiras un correo de confirmación con la infografía.</b> Revisa en
        la carpeta span :)
        <br />
        <small>
          Sí no envianos un correo a{" "}
          <a
            href="mailto:info@almayser.es"
            target={"_blank"}
            rel={"noopener noreferrer nofollow"}
          >
            info@almayser.es
          </a>{" "}
          y te lo reenviaremos.
        </small>
      </span>
    )}
  </form>
);
