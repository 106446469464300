import "./footer.scss";

import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import Contact from "../../../views/shared/Contact/Contact";

export const Footer = () => {
  return (
    <footer className={"o-footer"}>
      <div className={"container"}>
        <Contact />
        <hr className={"a-separator"} />
        <div className={"small"}>
          <StaticImage
            title={
              "Terapeutas florales homologados como Practitioners BFRP (Bach Foundation Registered Practitioners) acreditados por el Centro Oficial Bach de Inglaterra"
            }
            className={"a-footer__logo-certificate"}
            src={"../../../assets/images/certificate.png"}
            alt={"Certificado Bach Foundation registered Practitioner"}
          />
          <p>
            <small>
              Los remedios florales no tienen ningún tipo de riesgo, ni
              contraindicación. Es un producto de origen natural elaborado a
              partir de la filosofía del sistema floral del Dr. Bach, totalmente
              compatible con otras terapias y con la medicina tradicional. No
              obstante, Las flores de Bach, o la medicina alternativa presentada
              en esta web, no sustituye ningún tratamiento tradicional. Es
              aconsejable acudir a su médico de cabecera antes cualquier
              situación grave. Las flores de Bach tratan las emociones de las
              personas a través de terapia vibracional , no tratan enfermedades
              ni dolencias. La filosofía del Dr. Bach expone que los males
              físicos radican en males emocionales, y en la actualidad, ya hay
              muchos estudios que han demostrado que el bienestar emocional
              tiene un impacto directo en nuestra salud.
            </small>
          </p>
        </div>
        <div className={"m-footer-items "}>
          <div className={"a-links"}>
            <a
              href="/legal/aviso-legal/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Aviso legal
            </a>{" "}
            /{" "}
            <a
              href="/legal/politica-de-privacidad/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Privacidad
            </a>{" "}
            /{" "}
            <a
              href="/legal/politica-de-cookies/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Cookies
            </a>{" "}
            /{" "}
            <a
              href="/legal/politica-de-compras/#formas-de-pago"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Pagos
            </a>{" "}
            /{" "}
            <a
              href="/legal/politica-de-compras/#condiciones-de-envio"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Envíos
            </a>
          </div>
          <div className={"m-footer__certificate"}>
            <div>
              <p className={"a-footer__text"}>Almayser - Terapia Floral</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
