import { useState } from "react";

const useForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const data = new FormData(e.target);
    // Fake to test frontend
    // setTimeout( () => {
    //          setSubmitting(false);
    //          setSubmitted(true);
    //          setSuccess(true);
    // }, 3000);
    // setTimeout( () => {
    //     console.log('fail');
    //     setSubmitting(false);
    //     setSubmitted(false);
    //     setSuccess(false);
    //     setFail(true);
    // }, 5000);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(data).toString(),
    })
      .then((e) => {
        if (e.ok) {
          setSubmitting(false);
          setSubmitted(true);
          setSuccess(true);
        } else {
          setSubmitting(false);
          setSubmitted(true);
          setSuccess(false);
          setFail(true);
        }
      })
      .catch(() => {
        setSubmitting(false);
        setSubmitted(true);
        setSuccess(false);
        setFail(true);
      });
  };
  return {
    handleSubmit,
    submitting,
    submitted,
    success,
    fail,
  };
};

export default useForm;
