import "./contact.scss";

import * as React from "react";
import { useEffect } from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import circleShadow from "../../../assets/vectors/circle-shadow.svg";
import { Unete } from "../../../components/ModalForm/ContactForm/Unete";
import withSubscriber from "../../../components/ModalForm/ContactForm/withSubscriber";

const Contact: React.FC<any> = () => {
  const UneteWithForm = withSubscriber(Unete);
  return (
    <section className={"o-footer-contact container"}>
      <p className={"a-line-center"}>
        Únete a la comunidad para enterarte de todas las novedades y recibe de
        regalo una infografía con la información básica de las Flores de Bach.
      </p>
      <h2 className="a-title">Empieza tu camino floral con AlmaySer</h2>
      <UneteWithForm />
    </section>
  );
};

export default Contact;
