import "./contact-form.scss";

import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { PropsWithChildren, useEffect, useState } from "react";

interface PropsForm extends PropsWithChildren<any> {
  handleSubmit: any;
  submitting: boolean;
  success: boolean;
  fail: boolean;
}

export const availableDays = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
];
export const availableHours = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
export const availableTimesZones = [
  "A cualquier hora",
  "A primera hora",
  "Al mediodía",
  "Por la tarde",
  "A última hora",
  "Más opciones",
];

export const CitaOnline = (props: PropsForm) => {
  const [is_viewHours, setViewHours] = useState<boolean>(false);
  const [is_viewForm, setViewForm] = useState<boolean>(false);

  const handlerTimesZones = (event) => {
    if (event.target.value === "Más opciones") {
      setViewHours(true);
    } else {
      setViewHours(false);
    }
  };

  const { allStripePrice } = useStaticQuery(graphql`
    query GetUnitPricesallPrices {
      allStripePrice {
        nodes {
          unit_amount
          id
        }
      }
    }
  `);

  const priceConsultation = allStripePrice.nodes.find((stripePrice) => {
    return stripePrice.id === process.env.GATSBY_STRIPE_KEY_ONLINE_CONSULTATION;
  });

  const viewForm = () => {
    setViewForm(true);
    setTimeout(() => {
      document.getElementById("form-pide-cita").scrollIntoView({
        block: "start",
        behavior: "smooth",
        inline: "start",
      });
    }, 501); // this is to wait finish translataion
  };
  return (
    <>
      <h3 className={"o-modal-form__contact text-center"}>
        ¿Qué tipo de consulta quieres?
      </h3>
      <div className={"m-modal-form__select-meet"}>
        <a
          className={"a-button"}
          href={"/consulta-escrita/"}
          target={"_blank"}
          rel="noreferrer"
        >
          Consulta escrita
        </a>
        <span className={"a-button"} onClick={viewForm}>
          Reservar sesión
        </span>
      </div>
      <form
        id={"form-pide-cita"}
        name="pide-cita"
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        onSubmit={props.handleSubmit}
        className={`m-form m-form--modal text-center m-form__meet ${
          is_viewForm ? "" : "m-form__meet--hidden"
        }`}
      >
        <input type="hidden" name="form-name" value="pide-cita" />
        <label hidden> Nombre </label>
        <input type="text" name="nombre" placeholder="Nombre" required />
        <p className="hidden">
          <input type="text" name="bot-field" placeholder="Nombre" />
        </p>

        <label hidden>Email </label>
        <input type="email" name="email" placeholder="Email" required />
        <div>
          <p className={"h3"}>¿Qué días te van bien?</p>
          <div className={"m-form__days-section"}>
            {availableDays.map((day) => (
              <div key={day} className={"m-form__day"}>
                <label htmlFor={day}>{day}</label>
                <input type={"checkbox"} value={"Sí"} name={day} id={day} />
              </div>
            ))}
          </div>
        </div>
        <div>
          <p className={"h3"}>¿Qué horario te viene mejor?</p>
          <p>Nuestro horario de consultas es de 10 a 19</p>
          <select
            className={"m-select-flower__select"}
            onChange={handlerTimesZones}
            name={"timeZone"}
          >
            {availableTimesZones.map((timeZone, index) => (
              <option key={index} value={timeZone} id={`${timeZone}`}>
                {timeZone}
              </option>
            ))}
          </select>
          {is_viewHours && (
            <div className={"m-form__days-section"}>
              {availableHours.map((hour) => (
                <div key={hour} className={"m-form__day"}>
                  <label htmlFor={`hour${hour}`}>
                    de {hour}:00 a {hour + 1}:00
                  </label>
                  <input
                    type={"checkbox"}
                    value={`de {hour}:00 a {hour + 1}:00`}
                    name={`hour${hour}`}
                    id={`hour${hour}`}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <textarea
          placeholder="Observaciones: por ejemplo, necesito que sea el fin de semana..."
          name="observaciones"
          rows={4}
        />
        <p className={"text-center"}>
          Al solicitar cita recibirás un correo con una propuesta de cita para
          que la confirmes.
        </p>
        <div className={"a-private"}>
          <label htmlFor="politica">
            {" "}
            Acepto la{" "}
            <a
              href="/legal/politica-de-privacidad/"
              target="_blank"
              rel="nofollow noopener noreferrer"
              className={"a-policy"}
            >
              política de privacidad
            </a>{" "}
          </label>
          <input
            type="checkbox"
            name="politica"
            id="politica"
            value="acepta-politica"
            required
          />
        </div>
        <div className="center">
          {!props.success && (
            <button type="submit" className={"a-button"}>
              Solicitar cita{" "}
              {props.submitting && <span className="a-spinner" />}
            </button>
          )}
        </div>
        {props.fail && (
          <span className="a-fail">
            Ha ocurrido un <b>error</b>, puedes ponerte en contacto a través de{" "}
            <a
              href="mailto:info@almayser.es"
              target={"_blank"}
              rel={"noopener noreferrer nofollow"}
            >
              info@almayser.es
            </a>{" "}
            o vía{" "}
            <a
              href="https://wa.me/+34634464316"
              target={"_blank"}
              rel={"noopener noreferrer nofollow"}
            >
              {" "}
              whatsapp
            </a>
          </span>
        )}
        {props.success && (
          <span className="a-success">Formulario enviado con éxito</span>
        )}
        <p className={"text-rignt"} style={{ lineHeight: "1em" }}>
          *Precio {priceConsultation.unit_amount / 100}€ <br />{" "}
          <small>IVA incluido</small>{" "}
        </p>
      </form>
    </>
  );
};
